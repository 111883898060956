body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  /* max-width: 1180px; */
  flex: 1;
  padding: 1rem;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #000000;
  color: white;
}
